import { render, staticRenderFns } from "./CameraItem.vue?vue&type=template&id=1c84e526&scoped=true&"
import script from "./CameraItem.vue?vue&type=script&lang=ts&"
export * from "./CameraItem.vue?vue&type=script&lang=ts&"
import style0 from "./CameraItem.vue?vue&type=style&index=0&id=1c84e526&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c84e526",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VIcon,VSheet})
