


















































import { Component, Prop, Mixins, Watch, Ref } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import ConsoleCommand from './ConsoleCommand.vue'
import ConsoleItem from './ConsoleItem.vue'
import { SocketActions } from '@/api/socketActions'

@Component({
  components: {
    ConsoleCommand,
    ConsoleItem
  }
})
export default class Console extends Mixins(StateMixin) {
  @Prop({ type: Array, default: [] })
  items!: []

  @Prop({ type: String, default: 'id' })
  keyField!: string

  @Prop({ type: Number, default: 250 })
  height!: number

  @Prop({ type: Boolean, default: false })
  readonly!: boolean

  @Ref('scroller')
  dynamicScroller!: any

  _pauseScroll = false

  get availableCommands () {
    return this.$store.getters['console/getAllGcodeCommands']
  }

  get consoleCommand () {
    return this.$store.state.console.consoleCommand
  }

  set consoleCommand (val: string) {
    this.$store.commit('console/setConsoleCommand', val)
  }

  get flipLayout (): boolean {
    return this.$store.state.config.uiSettings.general.flipConsoleLayout
  }

  set flipLayout (_) {
    this.scrollToLatest(true)
  }

  mounted () {
    this.dynamicScroller.$el.addEventListener('scroll', this.onScroll)
  }

  beforeDestroy () {
    this.dynamicScroller.$el.removeEventListener('scroll', this.onScroll)
  }

  @Watch('items', { immediate: true })
  onItemsChange (_: any, oldItems: any[]) {
    if (this.dynamicScroller) {
      const el = this.dynamicScroller.$el

      if (this.flipLayout && (this._pauseScroll || !this.$store.state.console.autoScroll)) {
        const { scrollHeight, clientHeight } = el

        if (scrollHeight > clientHeight) {
          this.$nextTick(() => {
            el.scrollTop += el.scrollHeight - scrollHeight
          })
        }
      } else {
        this.scrollToLatest(oldItems?.length === 0)
      }
    }
  }

  updateScrollingPaused () {
    this.$nextTick(() => {
      const { scrollTop, scrollHeight, clientHeight } = this.dynamicScroller.$el

      const pauseScroll = this.flipLayout ? scrollTop > 1 : scrollHeight - scrollTop - clientHeight > 1

      if (this._pauseScroll !== pauseScroll) {
        this._pauseScroll = pauseScroll
        this.$emit('update:scrollingPaused', pauseScroll)
      }
    })
  }

  onScroll () {
    this.updateScrollingPaused()
  }

  scrollToLatest (force?: boolean) {
    if (this._pauseScroll && !force) return

    if (this.dynamicScroller) {
      if (
        this.$store.state.console.autoScroll ||
        this.readonly ||
        force
      ) {
        if (this.flipLayout) {
          this.dynamicScroller.scrollToItem(0)
        } else {
          this.dynamicScroller.scrollToBottom()
        }
      }

      this.updateScrollingPaused()
    }
  }

  sendCommand (command?: string) {
    if (command && command.length) {
      // If clients detect M112 input from the console, we should invoke the emergency_stop endpoint
      if (command && command.trim().toLowerCase() === 'm112') {
        SocketActions.printerEmergencyStop()
      }
      this.sendGcode(command)
      this.consoleCommand = ''
    }
  }

  handleEntryClick (command: string) {
    this.consoleCommand = command
  }
}
