














































import { Component, Mixins, Prop } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import GcodePreviewControlCheckbox from '@/components/widgets/gcode-preview/GcodePreviewControlCheckbox.vue'
import FilesMixin from '@/mixins/files'

@Component({
  components: { GcodePreviewControlCheckbox }
})
export default class GcodePreviewControls extends Mixins(StateMixin, FilesMixin) {
  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  get canFollowProgress () {
    const file = this.$store.getters['gcodePreview/getFile']
    const printerFile = this.$store.state.printer.printer.current_file

    return file && printerFile && (file.path + '/' + file.filename) === (printerFile.path + '/' + printerFile.filename)
  }
}
