








































































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import panzoom, { PanZoom } from 'panzoom'
import { BBox, LayerNr, LayerPaths } from '@/store/gcodePreview/types'
import { GcodePreviewConfig } from '@/store/config/types'

@Component({})
export default class GcodePreview extends Mixins(StateMixin) {
  @Prop({
    type: Boolean,
    default: true
  })
  disabled!: boolean

  @Prop({
    type: String
  })
  width!: string

  @Prop({
    type: String
  })
  height!: string

  @Prop({
    type: Number,
    default: Infinity
  })
  progress!: number

  @Prop({
    type: Number,
    default: 0
  })
  layer!: LayerNr

  focused = false

  panzoom?: PanZoom

  panning = false

  get isDelta (): boolean {
    const kinematics = this.$store.getters['printer/getPrinterSettings']('printer.kinematics')
    return kinematics === 'delta' || kinematics === 'rotary_delta'
  }

  get printerRadius (): number {
    return this.$store.getters['printer/getPrinterSettings']('printer.print_radius') ?? 100.0
  }

  get themeIsDark (): boolean {
    return this.$store.state.config.uiSettings.theme.isDark
  }

  get filePosition (): number {
    return this.$store.state.printer.printer.virtual_sdcard.file_position
  }

  get isMobile (): boolean {
    return this.$vuetify.breakpoint.mobile
  }

  get extrusionLineWidth () {
    return this.getUiSetting('extrusionLineWidth')
  }

  get moveLineWidth () {
    return this.getUiSetting('moveLineWidth')
  }

  get retractionIconSize () {
    return this.getUiSetting('retractionIconSize')
  }

  get drawBackground () {
    return this.getUiSetting('drawBackground')
  }

  get showAnimations () {
    return this.getUiSetting('showAnimations')
  }

  get shapeRendering () {
    return this.panning ? 'optimizeSpeed' : 'geometricPrecision'
  }

  get flipX (): boolean {
    return this.$store.state.config.uiSettings.gcodePreview.flip.horizontal
  }

  get flipY (): boolean {
    return this.$store.state.config.uiSettings.gcodePreview.flip.vertical
  }

  get flipTransform () {
    const {
      x,
      y
    } = this.viewBox

    const scale = [
      this.flipX ? -1 : 1,
      this.flipY ? -1 : 1
    ]

    if (this.isDelta) {
      return `scale(${scale.join()}) translate(0,0)`
    }

    const transform = [
      this.flipX ? -(x.max - x.min) : 0,
      this.flipY ? -(y.max - y.min) : 0
    ]

    return `scale(${scale.join()}) translate(${transform.join()})`
  }

  get bedSize (): BBox {
    const {
      stepper_x: stepperX,
      stepper_y: stepperY
    } = this.$store.getters['printer/getPrinterSettings']()

    if (this.isDelta) {
      const radius = this.printerRadius
      return {
        x: {
          min: -radius,
          max: radius
        },
        y: {
          min: -radius,
          max: radius
        }
      }
    }

    return {
      x: {
        min: stepperX?.position_min ?? 0,
        max: stepperX?.position_max ?? 100
      },
      y: {
        min: stepperY?.position_min ?? 0,
        max: stepperY?.position_max ?? 100
      }
    }
  }

  get viewBox (): BBox {
    const bounds = this.$store.getters['gcodePreview/getBounds']

    const {
      stepper_x: stepperX,
      stepper_y: stepperY
    } = this.$store.getters['printer/getPrinterSettings']()

    if (this.isDelta) {
      const radius = this.printerRadius
      return {
        x: {
          min: -radius,
          max: radius * 2
        },
        y: {
          min: -radius,
          max: radius * 2
        }
      }
    }

    if (stepperX === undefined || stepperY === undefined) {
      return {
        x: {
          min: bounds.x.min,
          max: bounds.x.max
        },
        y: {
          min: bounds.y.min,
          max: bounds.y.max
        }
      }
    }

    return {
      x: {
        min: Math.min(stepperX.position_min, bounds.x.min),
        max: Math.max(stepperX.position_max, bounds.x.max)
      },
      y: {
        min: Math.min(stepperY.position_min, bounds.y.min),
        max: Math.max(stepperY.position_max, bounds.y.max)
      }
    }
  }

  get svgViewBox () {
    const {
      x,
      y
    } = this.viewBox

    return `${x.min} ${y.min} ${x.max} ${y.max}`
  }

  get defaultLayerPaths (): LayerPaths {
    return {
      extrusions: '',
      moves: '',
      retractions: [],
      extrusionStarts: [],
      toolhead: {
        x: 0,
        y: 0
      }
    }
  }

  get svgPathCurrent (): LayerPaths {
    if (this.disabled) {
      return this.defaultLayerPaths
    }

    const layer = this.$store.getters['gcodePreview/getLayers'][this.layer]

    if (this.getViewerOption('followProgress')) {
      const end = this.$store.getters['gcodePreview/getMoveIndexByFilePosition'](this.filePosition)

      return this.$store.getters['gcodePreview/getPaths'](layer?.move ?? 0, end)
    }

    return this.$store.getters['gcodePreview/getPaths'](layer?.move ?? 0, this.progress)
  }

  get svgPathActive (): LayerPaths {
    if (this.disabled) {
      return this.defaultLayerPaths
    }

    return this.$store.getters['gcodePreview/getLayerPaths'](this.layer)
  }

  get svgPathPrevious (): LayerPaths {
    if (this.disabled || this.layer <= 0) {
      return this.defaultLayerPaths
    }

    return this.$store.getters['gcodePreview/getLayerPaths'](this.layer - 1)
  }

  get svgPathNext (): LayerPaths {
    const layers = this.$store.getters['gcodePreview/getLayers']

    if (this.disabled || this.layer >= layers.length) {
      return this.defaultLayerPaths
    }

    return this.$store.getters['gcodePreview/getLayerPaths'](this.layer + 1)
  }

  @Watch('isMobile')
  onIsMobileChanged () {
    if (this.panzoom) {
      if (this.isMobile) {
        this.panzoom.pause()
      } else {
        this.panzoom.resume()
      }
    }
  }

  @Watch('focused')
  onFocusedChanged () {
    if (this.isMobile && this.panzoom) {
      if (this.focused) {
        this.panzoom.resume()
      } else {
        this.panzoom.pause()
      }
    }
  }

  mounted () {
    this.panzoom = panzoom(this.$refs.svg as SVGElement, {
      maxZoom: 20,
      minZoom: 0.95,
      bounds: true,
      boundsPadding: 0.6,
      smoothScroll: this.showAnimations,

      beforeWheel: () => !this.focused
    })

    this.panzoom.on('panstart', () => {
      this.panning = true
    })

    this.panzoom.on('panend', () => {
      this.panning = false
    })
  }

  beforeDestroy () {
    this.panzoom?.dispose()
  }

  reset () {
    this.panzoom?.zoomTo(0, 0, 1)
  }

  getViewerOption (name: string) {
    return this.$store.getters['gcodePreview/getViewerOption'](name)
  }

  getUiSetting (name: keyof GcodePreviewConfig) {
    return this.$store.state.config.uiSettings.gcodePreview[name]
  }
}
