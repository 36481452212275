











import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class FileEditorText extends Vue {
  @Prop({ type: String, required: true })
  value!: string;

  @Prop({ type: Boolean, default: false })
  readonly!: boolean;

  emitChange (value: string | undefined) {
    this.$emit('change', value)
    this.$emit('input', value)
  }

  mounted () {
    this.$emit('ready')
  }
}
