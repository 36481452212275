






























































import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class PrinterStatsCard extends Vue {
  get cpuInfo () {
    const info = this.$store.getters['server/getSystemInfo']
    return info?.cpu_info || {}
  }

  get sdInfo () {
    const info = this.$store.getters['server/getSystemInfo']
    return info?.sd_info || {}
  }

  get distribution () {
    const info = this.$store.getters['server/getSystemInfo']
    return info?.distribution || {}
  }

  get services () {
    const info = this.$store.getters['server/getSystemInfo']
    return info?.available_services || []
  }

  get printerInfo () {
    return this.$store.state.printer.printer.info
  }

  get fileSystemUsedPercent () {
    // (250 / 500) * 100
    const total = this.fileSystemUsage.total
    const used = this.fileSystemUsage.used
    return Math.floor((used / total) * 100).toFixed()
  }

  get fileSystemUsage () {
    return this.$store.getters['files/getUsage']
  }
}
